<template>
    <a v-if="!to" href="#" :class="active ? 'bg-black' : 'hover:bg-black'" class="text-white flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg">
        <slot />
    </a>

    <router-link v-else v-slot="{ href, route, navigate, isActive }" :to="to" custom>
        <a :href="href" :class="isActive ? 'bg-black' : 'bg-transparent '" class="flex items-center text-gray-300 hover:text-gray-100 hover:bg-black bg-opacity-50 hover:bg-opacity-50" @click="navigate">
            <div :class="isActive ? 'text-gray-300 hover:text-gray-300' : ''" class="w-full text-center px-2 py-4">
                <slot />
            </div>
        </a>
    </router-link>
</template>

<script>
export default {
    props: {
        to: {
            type: Object,
            required: false,
            default: () => { "/error/404"; },
        },
        tooltip: {
            type: String,
            required: false,
            default: () => "",
        },
        active: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    data() {
        return {};
    },
    computed: {
        attributes() {
            return {
                ...this.$attrs,
            };
        },
    },
    mounted() {
        this.$forceUpdate();
    },
};
</script>
